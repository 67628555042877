import { inject, Inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseMessage } from '../../../shared/interfaces/req.res.interface';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeviceManagementService {
  private httpClient = inject(HttpClient);
  statusFilterVal = new BehaviorSubject(0);
  sortVal = new BehaviorSubject(1);
  constructor() { }

  // Get Device List
  getDeviceList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    // data.search_text ? params = params.append('search_name', data.search_text) : '';
    return this.httpClient.get<ResponseMessage>('get-devices', { params: params });
  }

  addEdit(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('add-device', data)
  }
  getBusinessDeviceloCation(business_id: any): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`get-business-locations?business_id=${business_id}`)
  }
  addUpdateDevice(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('add-device', data);
  }
  getDeviceDetail(id: any): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`device-details/${id}`);
  }
  deleteDevice(id: any): Observable<ResponseMessage> {
    return this.httpClient.delete<ResponseMessage>(`delete-device/${id}`)
  }
}
